.block-10 {
  height: calc(100% - var(--header-height));
  padding: 0 var(--margin);
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1.5rem));
  align-items: center;
  gap: var(--margin);

  @media (--laptop) {
    padding: calc(4 * var(--vrem)) 0 calc(5 * var(--vrem));
    grid-template-columns: repeat(2, calc(22 * var(--vrem)));
    gap: calc(5 * var(--vrem));
  }

  &__image {
    width: 100%;
    height: 20rem;

    @media (--laptop) {
      height: 100%;
    }
  }
}
