.block-14 {
  padding: 0 var(--margin);
  display: flex;
  align-items: center;
  column-gap: var(--margin);

  @media (--laptop) {
    height: 100%;
    padding: 0;
    column-gap: 14rem;
  }

  &__image.image {
    width: 100%;
    height: 22rem;

    @media (--laptop) {
      width: calc(36 * var(--vrem));
      height: calc(100% - (30 * var(--vrem)));
    }
  }

  &__large-image.image {
    width: 100%;
    height: 22rem;

    @media (--laptop) {
      width: calc(63 * var(--vrem));
      height: 100%;
    }
  }
}
