.claim {
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 7rem;

  @media (--laptop) {
    width: auto;
    height: 100%;
    padding: calc(18 * var(--vrem)) 0 calc(10 * var(--vrem));
    flex-direction: row;
    align-items: flex-start;
    column-gap: calc(12 * var(--vrem));
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4rem;

    @media (--laptop) {
    }

    &__text {
      text-align: center;

      @media (--laptop) {
        text-align: left;
      }
    }
  }

  &__image.image {
    width: 100%;
    height: 24rem;

    @media (--laptop) {
      width: calc(73 * var(--vrem));
      height: 100%;
    }
  }
}
