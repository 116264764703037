.block-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12rem 0;

  @media (--laptop) {
    height: 100%;
    padding: 22rem;
  }

  &__image.image {
    width: 15rem;
    height: 20rem;

    @media (--laptop) {
      width: calc(33 * var(--vrem));
      height: calc(45 * var(--vrem));
    }
  }
}
