.header {
  width: 100vw;
  padding: var(--margin) var(--margin) 0;
  box-sizing: border-box;
  background-color: transparent;
  color: var(--blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: var(--header-z-index);
  mix-blend-mode: multiply;

  @media (--laptop) {
    padding: var(--margin) var(--margin) 0;
  }

  &__logo {
    opacity: 0 !important;
    pointer-events: none !important;

    .page-in-sequence & {
      opacity: 1;
      pointer-events: all;
    }

    &__icon {
      width: 4.2rem;
      height: 5rem;
      display: block;
      background-image: url(/assets/icons/logo.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__hamburger {
    width: var(--columns-2);
    display: flex;
    justify-content: flex-start;

    @media (--laptop) {
    }
  }

  &__book {
    width: var(--columns-2);
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    pointer-events: none;

    .button {
      border-color: var(--white);
    }
  }
}
