.block-2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--margin);
  column-gap: var(--margin);

  @media (--laptop) {
    width: fit-content;
    align-items: flex-end;
    column-gap: calc(13 * var(--vrem));
    row-gap: 0;
  }

  &--v-2 {
    height: calc(100% - var(--header-height));
    align-items: center;

    @media (--laptop) {
      box-sizing: border-box;
      padding: calc(5 * var(--vrem));
    }
  }

  &--v-3 {
    height: calc(100% - var(--header-height));
    align-items: center;
    flex-direction: row-reverse;

    @media (--laptop) {
      height: 100%;
      padding: 0;
    }
  }

  &__image-small {
    width: 100%;
    height: 21rem;

    @media (--laptop) {
      width: calc(26 * var(--vrem));
      height: calc(38 * var(--vrem));
      margin-bottom: 5rem;
    }

    .block-2--v-2 & {
      @media (--laptop) {
        width: calc(34 * var(--vrem));
        height: calc(46 * var(--vrem));
        margin-bottom: 0;
      }
    }

    .block-2--v-3 & {
      @media (--laptop) {
        width: calc(37 * var(--vrem));
        height: calc(54 * var(--vrem));
        margin-bottom: 0;
      }
    }
  }

  &__image-large {
    width: 100%;
    height: 21rem;

    @media (--laptop) {
      width: calc(55 * var(--vrem));
      height: 100%;
    }

    .block-2--v-2 & {
      width: 100%;

      @media (--laptop) {
        width: calc(47 * var(--vrem));
      }
    }
  }
}
