:root {
  --font-inter: "Inter", sans-serif;
  --font-sofia: "Sofia Sans Condensed";
}

.inter {
  font-family: var(--font-inter);
}

.sofia {
  font-family: var(--font-sofia);
}

body {
  font-family: var(--font-inter);
  color: var(--blue);
}

i,
.italic {
  font-style: italic;
}

.thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.underlined {
  text-decoration: underline;
}

b {
  font-weight: 800;
}

h1 {
  font-size: calc(6.4 * var(--vrem));
  line-height: calc(5.6 * var(--vrem));

  @media (--laptop) {
    font-size: calc(13 * var(--vrem));
    line-height: calc(10 * var(--vrem));
  }
}

h2 {
  font-size: calc(6.4 * var(--vrem));

  @media (--laptop) {
    font-size: calc(12.8 * var(--vrem));
    line-height: calc(12.8 * var(--vrem));
  }
}

h3,
.h3 {
  font-size: calc(4.8 * var(--vrem));
}

h4 {
  font-size: 6.4rem;
  line-height: 5.6rem;

  @media (--laptop) {
    font-size: calc(9.6 * var(--vrem));
    line-height: calc(8 * var(--vrem));
  }
}

.p1 {
  font-size: calc(1.2 * var(--vrem));

  @media (--laptop) {
  }
}

.p2 {
  font-size: 1.4rem;
  line-height: 2.2rem;

  @media (--laptop) {
    font-size: calc(1.4 * var(--vrem));
    line-height: calc(1.8 * var(--vrem));
  }
}

.p3 {
  font-size: 1.6rem;

  @media (--laptop) {
    font-size: calc(1.6 * var(--vrem));
  }
}

.p4 {
  font-size: 1.6rem;
  line-height: 1.6rem;

  @media (--laptop) {
    font-size: calc(3.2 * var(--vrem));
    line-height: calc(3.2 * var(--vrem));
  }
}

.p5 {
  font-size: calc(6.4 * var(--vrem));
  line-height: calc(6 * var(--vrem));
}

.p6 {
  font-size: 4rem;
  line-height: 3rem;

  @media (--laptop) {
    font-size: calc(16 * var(--vrem));
    line-height: calc(13 * var(--vrem));
  }
}

.p7 {
  font-size: 1.8rem;
  line-height: 1.8rem;

  @media (--laptop) {
    font-size: calc(2.4 * var(--vrem));
    line-height: calc(2.4 * var(--vrem));
  }
}

.p8 {
  font-size: 1rem;
  line-height: 1.2rem;

  @media (--laptop) {
    font-size: calc(1 * var(--vrem));
    line-height: calc(1.2 * var(--vrem));
  }
}

.p9 {
  font-size: calc(2 * var(--vrem));
  line-height: calc(2 * var(--vrem));
}

.p10 {
  font-size: 5rem;
  line-height: 4.5rem;

  @media (--laptop) {
    font-size: calc(8 * var(--vrem));
    line-height: calc(8 * var(--vrem));
  }
}

.p11 {
  font-size: 3.2rem;
  line-height: 3.2rem;

  @media (--laptop) {
    font-size: calc(3.2 * var(--vrem));
    line-height: calc(3.2 * var(--vrem));
  }
}

.p12 {
  font-size: 2.4rem;
  line-height: 2.4rem;

  @media (--laptop) {
    font-size: calc(3.2 * var(--vrem));
    line-height: calc(3.2 * var(--vrem));
  }
}

.p13 {
  font-size: 4rem;
  line-height: 4rem;

  @media (--laptop) {
    font-size: 4.8rem;
    line-height: 4.8rem;
  }
}

.p14 {
  font-size: 1.4rem;
  line-height: 1.8rem;

  @media (--laptop) {
    font-size: calc(1.6 * var(--vrem));
    line-height: calc(2 * var(--vrem));
  }
}
