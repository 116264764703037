.nav {
  width: 100%;
  height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: var(--margin) var(--margin);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue);
  color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  will-change: clip-path;
  transition: clip-path var(--transition-duration--slow)
    var(--transition-ease--in-out-quint);
  pointer-events: none;
  opacity: 0;

  @media (--laptop) {
    padding: var(--margin) var(--margin) calc(3.2 * var(--vrem));
  }

  .nav-visible & {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    pointer-events: initial;
    opacity: 1;
    z-index: var(--nav-z-index);
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__hamburger {
      width: var(--columns-2);
      display: flex;
      justify-content: flex-start;
    }

    &__logo {
      &__icon {
        width: 4.2rem;
        height: 5rem;
        display: block;
        background-image: url(/assets/icons/logo--white.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__book {
      width: var(--columns-2);
      display: flex;
      justify-content: flex-end;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__footer {
    height: 5rem;
    display: flex;
    align-items: flex-end;
  }

  &__items {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    align-items: center;
    justify-content: space-between;

    @media (--laptop) {
      flex-direction: row;
      row-gap: 0;
      padding: 0 var(--columns-1);
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0.8rem;
      position: relative;

      @media (--laptop) {
        row-gap: 2.8rem;
      }

      &__image.image {
        width: 20rem;
        height: 26rem;
        position: absolute;
        top: 50%;
        left: -5rem;
        transform: translateY(-56%);
        transition: opacity var(--transition-duration--more-fast)
          var(--transition-ease--out-quint);
        will-change: opacity;
        opacity: 0;
        display: none;

        @media (--laptop) {
          display: block;
        }

        .nav__items__item:nth-child(even) & {
          left: auto;
          right: -5rem;
          transform: translateY(-44%);
        }

        .nav__items__item:hover & {
          @media (--laptop) {
            opacity: 1;
          }
        }

        &::before,
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: var(--blue);
          position: absolute;
          top: 0;
          left: 0;
        }

        &::before {
          z-index: 1;
          mix-blend-mode: color;
        }

        &::after {
          z-index: 2;
          mix-blend-mode: multiply;
          opacity: 0.5;
        }
      }
    }
  }
}
