.footer {
  min-width: 100vw;
  height: auto;
  background-color: var(--red);
  padding: 5rem var(--margin);
  display: flex;
  flex-direction: column;
  row-gap: calc(8 * var(--vrem));

  @media (--laptop) {
    height: 100%;
    padding: calc(5 * var(--vrem)) calc(6.4 * var(--vrem));
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (--laptop) {
      column-gap: calc(21 * var(--vrem));
    }

    &__typo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: calc(1.6 * var(--vrem));

      @media (--laptop) {
      }
    }

    &__logo {
      width: calc(7.2 * var(--vrem));
      height: calc(8.6 * var(--vrem));
      background-image: url(/assets/icons/logo--white-and-red.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top right;

      @media (--laptop) {
        width: calc(18 * var(--vrem));
        height: calc(19 * var(--vrem));
        background-position: top left;
        flex-shrink: 0;
      }
    }
  }

  &__utils {
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    row-gap: 6rem;

    @media (--laptop) {
      display: grid;
      grid-template-columns: repeat(3, min-content);
      //column-gap: calc(21 * var(--vrem));
      row-gap: calc(4 * var(--vrem));
      justify-content: space-between;
    }

    &__util {
      width: calc(18 * var(--vrem));
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: calc(1.6 * var(--vrem));

      &--nav {
        order: 1;
        width: 15rem;

        @media (--laptop) {
          width: calc(18 * var(--vrem));
        }
      }

      &--address {
        width: 100%;
        order: 3;

        @media (--laptop) {
          order: 2;
          width: calc(32 * var(--vrem));
        }
      }

      &--instagram {
        order: 2;
        width: 15rem;

        @media (--laptop) {
          order: 3;
          width: calc(18 * var(--vrem));
          margin-left: 0;
        }
      }

      &--credits {
        order: 6;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        @media (--laptop) {
          order: 4;
          width: calc(18 * var(--vrem));
          flex-direction: column;
          justify-content: flex-start;
        }

        > * {
          width: 15rem;
          padding-right: 4rem;

          @media (--laptop) {
            width: auto;
            padding-right: 0;
          }
        }
      }

      &--contacts {
        order: 4;
        width: 100%;

        @media (--laptop) {
          width: calc(32 * var(--vrem));
        }
      }

      &--email {
        order: 5;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;

        @media (--laptop) {
          width: calc(18 * var(--vrem));
        }

        > * {
          width: 15rem;

          @media (--laptop) {
            width: auto;
          }
        }
      }

      &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: calc(0.8 * var(--vrem));

        &__lang-icon-wrapper {
          display: flex;
          align-items: center;
          column-gap: calc(1.6 * var(--vrem));
        }

        &__lang-icon {
          width: calc(1.8 * var(--vrem));
          height: calc(1.8 * var(--vrem));
          display: inline-block;
          background-image: url(/assets/icons/language--white.svg);
          background-position: center left;
          background-size: contain;
        }

        &__social {
          display: flex;
          align-items: center;
          column-gap: 1.6rem;

          @media (--laptop) {
            column-gap: calc(1.6 * var(--vrem));
          }

          &::before {
            content: "";
            width: 2.8rem;
            height: 2.8rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @media (--laptop) {
              width: calc(2.8 * var(--vrem));
              height: calc(2.8 * var(--vrem));
            }
          }

          &--maratea {
            &::before {
              background-image: url(/assets/icons/instagram-maratea.svg);
            }
          }

          &--minervetta {
            &::before {
              background-image: url(/assets/icons/instagram-minervetta.svg);
            }
          }

          &--bazar {
            &::before {
              background-image: url(/assets/icons/instagram-bazar.svg);
            }
          }
        }
      }
    }
  }
}
