.page-in-sequence {
  .main__content {
    padding: 16rem 0 9rem;
    column-gap: 14rem;

    @media (--laptop) {
      padding: 0 0 0 var(--margin);
    }
  }

  &.prenota {
    .main__content {
      padding: 16rem 0 0;

      @media (--laptop) {
        padding: 0 0 0 var(--margin);
      }
    }
  }

  &.living {
    .main__content {
      @media (--laptop) {
        padding: 0 14rem 0 var(--margin);
      }
    }
  }
}
