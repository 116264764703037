:root {
  --white: #ffffff;
  --blue: #2e3190;
  --red: #b8272d;
}

.c-white {
  color: var(--white);
}

.c-blue {
  color: var(--blue);
}

.c-red {
  color: var(--red);
}

.bc-white {
  background-color: var(--white);
}

.bc-blue {
  background-color: var(--blue);
}

.bc-red {
  background-color: var(--red);
}
