.italy {
  width: 100%;
  padding: 0 var(--margin);

  @media (--laptop) {
    width: calc(45 * var(--vrem));
    height: 100%;
    padding: calc(18 * var(--vrem)) 0 calc(10 * var(--vrem));
  }

  svg {
    width: 100%;
    height: 37rem;

    @media (--laptop) {
      width: 100%;
      height: 100%;
    }

    path {
      pointer-events: none;
    }
  }
}
