.block-3 {
  box-sizing: border-box;
  padding: 0 var(--margin);
  display: flex;
  column-gap: var(--margin);

  @media (--laptop) {
    height: 100%;
    padding: calc(15 * var(--vrem)) 0 calc(10 * var(--vrem));
    flex-direction: row;
    align-items: center;
    column-gap: var(--gutter);
  }

  &--variant-1 {
    @media (--laptop) {
      padding: calc(20 * var(--vrem)) 0;
    }
  }

  &__image {
    width: 100%;
    height: 12rem;

    @media (--laptop) {
      width: calc(40 * var(--vrem));
      height: 100%;
    }

    .block-3--variant-1 & {
      @media (--laptop) {
        width: calc(29 * var(--vrem));
      }
    }
  }
}
