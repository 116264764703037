.block-6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
  color: white;

  @media (--laptop) {
    width: fit-content;
    height: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    background-color: var(--blue);
  }

  &__images {
    width: 100%;
    box-sizing: border-box;
    padding: 0 var(--margin);
    display: flex;
    column-gap: var(--margin);

    @media (--laptop) {
      width: fit-content;
      align-items: center;
      column-gap: 0;
      padding: 0;
      background-color: var(--white);
      margin-right: calc(44 * var(--vrem));
    }
  }

  &__image-small {
    width: 100%;
    height: 20rem;

    @media (--laptop) {
      width: calc(33 * var(--vrem));
      height: calc(45 * var(--vrem));
      margin: 0 calc(40 * var(--vrem)) 0 calc((40 * var(--vrem)) - 14rem);
    }
  }

  &__image.image {
    width: 100%;
    height: 20rem;

    @media (--laptop) {
      width: calc(124.8 * var(--vrem));
      height: 100vh;
    }

    img {
      object-position: center left;
    }
  }

  &__quote.quote {
    width: calc(100% - (2 * var(--margin)));
    z-index: 1;
    padding: 5rem var(--margin);

    @media (--laptop) {
      width: fit-content;
      padding: 0 calc(14 * var(--vrem));
      background-color: transparent;
    }
  }
}
