.block-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 3rem 0;

  @media (--laptop) {
    height: 100%;
    padding: calc(15 * var(--vrem)) 0;
    flex-direction: row;
    column-gap: calc(10 * var(--vrem));
    align-items: stretch;
  }

  &--with-text {
    padding: 0 var(--margin);
    row-gap: 6rem;

    @media (--laptop) {
      row-gap: 0;
      padding: calc(15 * var(--vrem)) 0 calc(10 * var(--vrem));
    }
  }

  &--with-quote {
    @media (--laptop) {
      padding: calc(15 * var(--vrem)) 0 calc(10 * var(--vrem));
    }
  }

  &__large-image.image {
    width: 21rem;
    height: 31rem;
    flex-shrink: 0;

    @media (--laptop) {
      width: calc(44 * var(--vrem));
      height: 100%;
    }

    &:nth-child(even) {
      width: 25rem;
      height: 36rem;

      @media (--laptop) {
        width: calc(44 * var(--vrem));
        height: 100%;
      }
    }
  }

  &__text {
    text-align: center;

    @media (--laptop) {
      text-align: left;
    }
  }

  &__image-and-text {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 6rem;

    @media (--laptop) {
      height: 100%;
      row-gap: 0;
      align-items: flex-start;
      justify-content: space-between;
    }

    &--inverted {
      @media (--laptop) {
        flex-direction: column-reverse;
      }
    }

    .block-8--with-quote & {
      align-items: center;
    }
  }

  &__quote.quote {
    @media (--laptop) {
      font-size: calc(4.8 * var(--vrem));
      line-height: calc(4.8 * var(--vrem));
    }
  }

  &__image.image {
    width: calc(15 * var(--vrem));
    height: calc(24 * var(--vrem));
    margin-bottom: 0;
    margin-top: auto;

    @media (--laptop) {
      width: calc(22 * var(--vrem));
      height: calc(33 * var(--vrem));
    }

    .block-8__image-and-text--inverted & {
      margin-top: 0;
    }
  }
}
