.main {
  width: 100%;
  height: fit-content;
  z-index: var(--main-z-index);
  /*opacity: 0;*/
  transition: opacity var(--transition-duration--slower)
    var(--transition-ease--out-expo);
  display: flex;
  flex-direction: column;

  .loader-hidden & {
    opacity: 1;
  }

  @media (--laptop) {
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    flex-shrink: 0;
  }

  &__content {
    width: 100%;
    height: fit-content;
    z-index: var(--main-z-index);
    display: flex;
    flex-direction: column;
    row-gap: 6rem;

    @media (--laptop) {
      width: max-content;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      column-gap: 18rem;
      padding: 0 calc(10 * var(--vrem)) 0 var(--margin);
      position: relative;
      flex-shrink: 0;
    }

    &__header {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  section {
    flex-shrink: 0;
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.header-in-view-trigger {
  width: 1px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  &__last {
    left: calc(100% - 100vw);
  }
}

.toggle-nav {
  cursor: pointer;
}
