.block-4 {
  width: calc(100% - (2 * var(--margin)));
  display: flex;
  align-items: flex-start;
  position: relative;
  box-sizing: border-box;
  padding: 6rem var(--margin);
  margin: 0 var(--margin);

  @media (--laptop) {
    width: calc(144 * var(--vrem));
    height: 100%;
    min-width: 100vw;
    padding: 5rem;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
  }

  &--right {
    justify-content: flex-end;
  }

  &__image.image {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  &--with-layer {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: #000000;
      opacity: 0.1;
    }
  }

  &__quote.quote {
    z-index: 2;
    background-color: transparent;
    align-self: flex-start;
    padding: 0;

    @media (--laptop) {
      font-size: calc(6.4 * var(--vrem));
      line-height: calc(6 * var(--vrem));
      align-self: center;
    }

    img {
      object-position: 20%;

      @media (--laptop) {
        object-position: center;
      }
    }
  }
}
