.block-13 {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--laptop) {
    height: 100%;
    flex-direction: row;
  }

  &__image.image {
    width: 20rem;
    height: 30rem;

    @media (--laptop) {
      width: calc(36 * var(--vrem));
      height: calc(52 * var(--vrem));
    }
  }
}
