* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-size: 2.6666666667vw;

  @media (--laptop) {
    height: 100vh;
    font-size: 0.78125vw;
  }
}

body {
  background-color: var(--white);
  width: 100%;
  height: 100%;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  /*&::-webkit-scrollbar {
    display: none;
  }*/

  /*@media (--laptop) {
    overflow: hidden;
  }*/

  @media (--laptop) {
    width: fit-content;
    display: flex;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
