.block-11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6rem;
  padding: 0 var(--margin);

  @media (--laptop) {
    height: 100%;
    flex-direction: row;
    position: relative;
  }

  &__image-large.image {
    width: 100%;
    height: 42rem;

    @media (--laptop) {
      width: calc(55 * var(--vrem));
      height: 100%;
    }
  }

  &__quote-wrapper {
    height: 100%;
    padding: 5.6rem 0;
    z-index: 0;
    display: flex;
    align-items: center;

    @media (--laptop) {
      padding: 0 calc(15 * var(--vrem));
    }
  }

  &__quote.quote {
    @media (--laptop) {
    }
  }
}
