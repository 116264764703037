.button {
  box-sizing: border-box;
  padding: 1rem 1.2rem;
  border-radius: 0.5rem;
  background-color: var(--blue);
  color: var(--white);
  font-weight: 500;
  font-size: 1.2rem;
  border: 1px solid var(--blue);

  &--secondary {
    background-color: transparent;
    border: 1px solid var(--white);
  }
}
