.language-selector {
  display: flex;
  align-items: center;
  column-gap: calc(0.8 * var(--vrem));

  &__icon {
    width: calc(1.8 * var(--vrem));
    height: calc(1.8 * var(--vrem));
    display: inline-block;
    background-image: url(/assets/icons/language.svg);
    background-position: center left;
    background-size: contain;

    .language-selector--white & {
      background-image: url(/assets/icons/language--white.svg);
    }
  }
}
