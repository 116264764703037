.block-9 {
  padding: 0 var(--margin);
  display: flex;
  justify-content: center;

  @media (--laptop) {
    height: 100%;
    padding: 0;
  }

  &__image.image {
    width: 100%;
    height: 47rem;

    @media (--laptop) {
      width: calc(63 * var(--vrem));
      height: 100%;
    }

    .block-9--variant-1 & {
      width: 21rem;
      height: 33rem;

      @media (--laptop) {
        width: calc(63 * var(--vrem));
        height: 100%;
      }
    }
  }
}
