.next-page {
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  padding: 13.4rem var(--margin) 14.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12.8rem;
  background-color: var(--blue);
  color: var(--white);
  position: relative;

  @media (--laptop) {
    height: 100%;
    padding: 0 24rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &--red {
    background-color: var(--red);
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.4rem;

    @media (--laptop) {
      align-items: flex-start;
    }
  }

  &__image {
    width: 100%;
    height: 25rem;
    position: absolute;
    bottom: 0;
    mix-blend-mode: multiply;

    @media (--laptop) {
      width: calc(71 * var(--vrem));
      height: calc(74 * var(--vrem));
      bottom: auto;
      right: calc((-71 * var(--vrem)) + 37.2rem);
    }
  }

  &__arrow {
    position: relative;
    transform: rotate(90deg);

    @media (--laptop) {
      transform: none;
    }

    svg {
      width: 21rem;
      height: 21rem;
      overflow: visible;

      @media (--laptop) {
        width: 26.4rem;
        height: 26.4rem;
      }

      circle {
        --percent: 100;
        --radius: 10.5rem;
        --pi: 3.14;
        --circumference: calc(var(--radius) * 2 * var(--pi));
        --stroke-length: var(--circumference);
        --stroke-offset: calc(
          var(--circumference) - (var(--circumference) * var(--percent) / 100)
        );
        r: var(--radius);
        stroke-dasharray: var(--stroke-length);
        stroke-dashoffset: var(--stroke-offset);
        fill: none;
        stroke: #fff;

        @media (--laptop) {
          --radius: 13.2rem;
        }
      }
    }

    &__line {
      width: calc(12 * var(--vrem));
      height: 1px;
      background-color: var(--white);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &::before,
      &::after {
        content: "";
        width: 1px;
        height: calc(1.4 * var(--vrem));
        position: absolute;
        right: 0;
        background-color: var(--white);
      }

      &::before {
        bottom: calc(100% - 1px);
        transform-origin: bottom left;
        transform: rotate(-45deg);
      }

      &::after {
        top: calc(100% - 1px);
        transform-origin: top left;
        transform: rotate(45deg);
      }
    }
  }
}
