.booking-info {
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--margin);
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  align-items: stretch;
  margin-top: -3.6rem;

  @media (--laptop) {
    width: calc(34 * var(--vrem));
    height: 100%;
    padding: calc(18 * var(--vrem)) 0 calc(10 * var(--vrem));
    justify-content: space-between;
    row-gap: 0;
    margin-top: 0;
  }

  &__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 3.2rem;

    @media (--laptop) {
      row-gap: calc(3.2 * var(--vrem));
    }

    &__items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 3.2rem;

      @media (--laptop) {
        row-gap: calc(3.2 * var(--vrem));
      }
    }

    &__claim {
      margin-top: 5rem;

      @media (--laptop) {
        margin-top: calc(11 * var(--vrem));
      }
    }
  }

  &__cta {
    background-color: var(--blue);
    padding: calc(1.6 * var(--vrem));
    text-align: center;
    border-radius: 0.5rem;
    color: var(--white);
  }
}
