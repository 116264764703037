.history {
  width: 100%;
  padding: 0 var(--margin);

  @media (--laptop) {
    width: calc(35 * var(--vrem));
    height: 100%;
    padding: calc(18 * var(--vrem)) 0 calc(10 * var(--vrem));
  }

  &__info {
    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 2.4rem;
    }
  }
}
