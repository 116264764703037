.page-transitioner {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--page-transitioner-z-index);
  pointer-events: none;
  user-select: none;
  background-color: var(--white);
  opacity: 1;
  transition: opacity var(--transition-duration--fastest) linear;

  &--hidden {
    opacity: 0;
  }
}
