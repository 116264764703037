.block-7 {
  width: 100%;
  padding: 0 var(--margin);
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @media (--laptop) {
    width: fit-content;
    height: 100%;
    padding: calc(15 * var(--vrem)) 0;
  }

  &__image.image {
    width: 100%;
    height: 29rem;

    @media (--laptop) {
      width: calc(85 * var(--vrem));
      height: 100%;
    }
  }
}
