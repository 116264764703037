.cover {
  width: 100%;
  box-sizing: border-box;
  padding: calc(4 * var(--vrem)) var(--margin) 0;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  align-items: center;

  @media (--laptop) {
    width: fit-content;
    min-width: 50vw;
    height: 100%;
    flex-direction: row;
    row-gap: 0;
    align-items: flex-end;
    column-gap: calc(18 * var(--vrem));
    padding: 0;
  }

  &--inverted {
    @media (--laptop) {
      padding: calc(4 * var(--vrem)) 0 calc(5 * var(--vrem));
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    row-gap: 0.4rem;

    @media (--laptop) {
      align-items: flex-start;
      padding-bottom: calc(5 * var(--vrem));
    }

    .cover--inverted & {
      @media (--laptop) {
        padding-bottom: 0;
      }
    }
  }

  &__image {
    width: 28rem;
    height: 42rem;

    @media (--laptop) {
      width: calc(47 * var(--vrem));
      height: calc(
        100% - var(--header-height) - (5 * var(--vrem)) - (4 * var(--vrem))
      );
      margin-bottom: calc(5 * var(--vrem));
    }

    &--small {
      width: 15rem;
      height: 21rem;

      @media (--laptop) {
        width: calc(47 * var(--vrem));
        height: calc(65 * var(--vrem));
        margin-bottom: calc(5 * var(--vrem));
      }
    }

    &--large {
      @media (--laptop) {
        height: 100%;
        width: calc(55 * var(--vrem));
        margin-bottom: 0;
      }
    }

    .cover--inverted & {
      @media (--laptop) {
        width: calc(47 * var(--vrem));
        height: calc(100% - var(--header-height));
      }
    }
  }
}
