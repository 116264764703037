.directions {
  width: 100%;
  padding: 6rem var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blue);
  color: var(--white);
  row-gap: 6rem;

  @media (--laptop) {
    width: fit-content;
    height: 100%;
    padding: 0 calc(20 * var(--vrem)) 0 calc(12 * var(--vrem));
    flex-direction: row;
    align-items: flex-start;
    column-gap: calc(10 * var(--vrem));
    row-gap: 0;
  }

  > * {
    flex-shrink: 0;
  }

  &__utils {
    order: 3;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 6rem;

    @media (--laptop) {
      width: auto;
      height: calc(100% - (28 * var(--vrem)));
      margin-bottom: calc(10 * var(--vrem));
      margin-top: auto;
      justify-content: space-between;
      row-gap: 0;
    }

    &__util {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1.6rem;

      @media (--laptop) {
        row-gap: calc(1.6 * var(--vrem));
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &--space-l {
        ul {
          row-gap: 1.6rem;

          @media (--laptop) {
            row-gap: calc(1.2 * var(--vrem));
          }
        }
      }

      &__social {
        display: flex;
        align-items: center;
        column-gap: 1.6rem;

        @media (--laptop) {
          column-gap: calc(1.6 * var(--vrem));
        }

        &::before {
          content: "";
          width: 2.8rem;
          height: 2.8rem;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          @media (--laptop) {
            width: calc(2.8 * var(--vrem));
            height: calc(2.8 * var(--vrem));
          }
        }

        &--maratea {
          &::before {
            background-image: url(/assets/icons/instagram-maratea.svg);
          }
        }

        &--minervetta {
          &::before {
            background-image: url(/assets/icons/instagram-minervetta.svg);
          }
        }

        &--bazar {
          &::before {
            background-image: url(/assets/icons/instagram-bazar.svg);
          }
        }
      }
    }
  }
}
