.page-cards {
  height: 100%;
  box-sizing: border-box;
  padding: 0 var(--margin) 12rem;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;

  @media (--laptop) {
    padding: calc(10 * var(--vrem)) 0;
    flex-direction: row;
    column-gap: calc(7.2 * var(--vrem));
  }

  &__card {
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    row-gap: 1.6rem;
    position: relative;

    @media (--laptop) {
      padding-bottom: 0;
      padding-left: calc(1.6 * var(--vrem));
      row-gap: calc(1.6 * var(--vrem));
      align-items: flex-start;
    }

    &:last-of-type {
      padding-bottom: 0;

      &::before {
        display: none;

        @media (--laptop) {
          display: block;
        }
      }
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: var(--blue);
      opacity: 0.2;

      @media (--laptop) {
        width: 1px;
        height: 100%;
        top: 0;
        bottom: auto;
      }
    }

    &__image {
      width: 100%;
      height: 25rem;

      @media (--laptop) {
        width: calc(32 * var(--vrem));
        height: calc(40 * var(--vrem));
      }
    }

    &__number-and-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        @media (--laptop) {
          display: none;
        }
      }
    }

    &__title {
      display: none;

      @media (--laptop) {
        display: block;
      }
    }

    &__number-and-title,
    &__title {
      line-height: calc(2.4 * var(--vrem));
    }
  }
}
