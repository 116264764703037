.intro {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16rem var(--margin) 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.4rem;
  position: relative;

  @media (--laptop) {
    width: calc(64 * var(--vrem));
    padding: var(--margin) 0;
    justify-content: center;
    row-gap: calc(3.2 * var(--vrem));
  }

  &__logo {
    width: 10rem;
    height: 12rem;
    background-image: url(/assets/icons/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media (--laptop) {
      width: calc(17 * var(--vrem));
      height: calc(20 * var(--vrem));
    }
  }

  &__logotipo {
    width: 100%;
    height: 10rem;
    background-image: url(/assets/icons/logotipo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media (--laptop) {
      height: calc(12.4 * var(--vrem));
    }
  }

  &__utils {
    margin-top: 6rem;

    @media (--laptop) {
      position: absolute;
      left: 0;
      bottom: var(--margin);
    }
  }

  &::after {
    content: "";
    width: calc(100% - (var(--margin) * 2));
    height: 1px;
    background-color: var(--blue);
    position: absolute;
    bottom: 0;
    left: var(--margin);
    opacity: 0.2;

    @media (--laptop) {
      display: none;
    }
  }
}
