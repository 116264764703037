.quote {
  width: 100%;
  max-height: 100%;
  padding: 0 var(--margin);
  align-self: center;
  font-size: 3.6rem;
  line-height: 3.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  color: var(--blue);
  text-align: center;

  @media (--laptop) {
    width: fit-content;
    font-size: calc(10 * var(--vrem));
    line-height: calc(9 * var(--vrem));
    text-align: left;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 1.6rem;
  }

  &--red {
    color: var(--red);
  }

  &--white {
    background-color: var(--blue);
    color: var(--white);
  }

  > p {
  }

  br {
    display: none;

    @media (--laptop) {
      display: inline;
    }
  }

  &__open {
    &--desktop {
      display: none;

      @media (--laptop) {
        display: inline-block;
      }
    }

    &--mobile {
      @media (--laptop) {
        display: none;
      }
    }
  }

  &__open,
  &__close {
    color: var(--red);

    .quote--red & {
      color: var(--blue);
    }

    .quote--white & {
      color: var(--white);
    }
  }

  b {
    font-weight: 800;
  }
}
