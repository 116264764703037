.house-map {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (--laptop) {
    width: fit-content;
    flex-direction: row;
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: calc(0.4 * var(--vrem));
    padding: calc(2.4 * var(--vrem)) var(--margin) 0;

    @media (--laptop) {
      align-items: flex-start;
      padding: calc(20.4 * var(--vrem)) 0 calc(10 * var(--vrem));
    }

    &__item {
      width: 100%;
      display: flex;
      column-gap: calc(6.4 * var(--vrem));
      align-items: flex-end;
      position: relative;
      padding-bottom: calc(0.4 * var(--vrem));
      opacity: 0.2;
      cursor: pointer;

      &--active {
        opacity: 1;
      }

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: currentColor;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &__number {
        width: calc(4 * var(--vrem));
      }
    }
  }

  &__images {
    width: 100%;
    height: 46rem;
    position: relative;
    overflow: hidden;

    @media (--laptop) {
      width: 80vw;
      height: 100%;
    }

    &__image {
      width: 82rem;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;

      @media (--laptop) {
        width: 150%;
        height: 150%;
      }

      &--active {
        opacity: 1;
      }

      &--visible {
        opacity: 0.2;
      }

      img {
        object-fit: contain;
      }
    }
  }
}
