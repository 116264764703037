.block-1 {
  height: 100%;
  box-sizing: border-box;
  padding: 0 var(--margin);

  @media (--laptop) {
    padding: 0;
  }

  &__image {
    height: 23rem;

    @media (--laptop) {
      width: 100vw;
      height: 100%;
    }
  }
}
