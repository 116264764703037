.block-12 {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6rem;
  padding: 0 var(--margin);

  @media (--laptop) {
    width: fit-content;
    height: 100%;
    flex-direction: row;
    align-items: flex-end;
    padding: 0;
  }

  &__text {
    width: 100%;
    height: calc(100vw - (2 * var(--margin)));
    margin: 0 var(--margin);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (--laptop) {
      height: 100vh;
      width: fit-content;
      margin: 0;
      padding: 0 calc(32 * var(--vrem)) 0 calc(7 * var(--vrem));
      text-align: left;
      justify-content: flex-start;
    }

    .block-12--variant-2 & {
      @media (--laptop) {
        margin-right: calc((-21 * var(--vrem)) - 14rem);
      }
    }
  }

  &__image.image {
    width: 100%;
    height: 47rem;
    flex-shrink: 0;

    @media (--laptop) {
      width: calc(57 * var(--vrem));
      height: 100%;
    }

    .block-12--variant-1 & {
      max-width: 28rem;
      height: 42rem;

      @media (--laptop) {
        width: calc(57 * var(--vrem));
        height: 100%;
        max-width: none;
      }
    }
  }
}
