:root {
  --canvas-z-index: 0;
  --main-z-index: 1;
  --submenu-z-index: 702;
  --cart-modal-z-index: 800;
  --cookie-banner-z-index: 800;
  --scroll-down-z-index: 992;
  --header-z-index: 993;
  --nav-z-index: 995;
  --page-transitioner-z-index: 996;
  --dots-z-index: 996;
  --cursor-z-index: 997;
  --loader-z-index: 998;
  --columns-z-index: 999;

  --vrem: 1.1160714286vh;

  /* PADDING AND MARGINS */

  --v-spacer-xxl: calc(20 * var(--vrem));
  --v-spacer-xl: calc(14 * var(--vrem));
  --v-spacer-l: calc(10 * var(--vrem));
  --v-spacer-m: calc(6.6 * var(--vrem));
  --v-spacer-s: calc(3.6 * var(--vrem));
  --v-spacer-xs: calc(3 * var(--vrem));
  --v-spacer-xxs: calc(1.6 * var(--vrem));

  --h-spacer-l: calc(var(--columns-2) + var(--gutter));
  --h-spacer-m: var(--columns-1);
  --h-spacer-s: var(--gutter);

  @media (--laptop) {
    --vrem: 1.2019230769vh;
  }
}
